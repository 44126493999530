import React from 'react';
import { Link } from 'gatsby';

import './style.scss';

 const Button = ({link, text, color, marginX, marginY}) => {
  return (
    <>
    {link.includes('http') ? 
        <a 
          className={`
          theCtaButton 
          ${color === 'yellow' ? 'yellowButton' : null}
          ${color === 'blue' ? 'blueButton' : null}
          ${color === 'orange' ? 'orangeButton' : null}
          ${color === 'loxam' ? 'loxamButton' : null}
          ${marginX ? 'buttonMarginX' : null}
          ${marginY ? 'buttonMarginY' : null}
          `} 
          href={link} 
          target="_blank"
          rel="noopener noreferrer"
          >
            {text}
        </a>
        : 
        <Link
          className={`
          theCtaButton 
          ${color === 'yellow' ? 'yellowButton' : null}
          ${color === 'blue' ? 'blueButton' : null}
          ${color === 'orange' ? 'orangeButton' : null}
          ${marginX ? 'buttonMarginX' : null}
          ${marginY ? 'buttonMarginY' : null}
          `} 
          to={link}
          >
            {text}
        </Link>}
    </>
  );
}
export default Button;