import React from 'react';
import keygen from '../../utils/keygen';
import { Link } from 'gatsby';

const BreadCrumbs = ({path}) => {

  let pathParts = path.split("/");
  pathParts.shift();
  const linkLevel1 = `/${pathParts[0]}`;
  const linkLevel2 = `/${pathParts[0]}/${pathParts[1]}`;
  const linkLevel3 = `/${pathParts[0]}/${pathParts[1]}/${pathParts[2]}`;
  return (
    <div className="breadcrumbs">
      <Link key={keygen()} to="/" ><img src="/ramihead.svg" alt="" /></Link> {pathParts.map((part, index) => {
        let link = linkLevel1;
        if(index === 1) {
          link = linkLevel2
        } else if(index === 2) {
          link = linkLevel3
        }
        return( 
         <Link key={keygen()} to={link} >/{part}</Link>
        );
      })}
    </div>
  );
}
export default BreadCrumbs;