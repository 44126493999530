import React from 'react';

const CategoryTag = ({category})=> {
 
  return (
    <div className="categoryTag">
    {category}
    </div>
  );
}
export default CategoryTag;