import React from 'react';
import Layout from '../../containers/layout';
import Hero from '../../components/hero';
import keygen from '../../utils/keygen';


import './style.scss';

const OmxSingle = ({ matchingRelease, backlink, location }) => {
  const content = matchingRelease.node.post_content.replace('<div />', '');
  return (
    <Layout location={location} title={matchingRelease.node.post_title} description="release from omx helsinki">


        <Hero 
        title={matchingRelease.node.post_title}
        date={matchingRelease.node.post_date}
        bgcolor='yellow' 
        heroStyle="post"
        path={location.pathname}
        showDate={true}
        />
        
        <div className="omxContent">
        <a className="backToArchive" href={backlink}><img src="/arrow-left.svg" alt="back" /><span>Back to archive</span></a>
          <div dangerouslySetInnerHTML={{ __html: content }}  />  
         
          <div className="annexFiles">
        
            {matchingRelease.node.annex_files ? matchingRelease.node.annex_files.map( annex => {
              let annexfile = annex.file.replace(' ', '+');
              annexfile = annex.file.replace('%20', '+');       
              annexfile = annex.file.replace('ö', 'o%CC%88');
              
              return (
              <p key={keygen()}><a href={`https://ramirentdotcom.s3-eu-west-1.amazonaws.com/omxannex/${annexfile}`}>{annex.title}</a></p>
              );
            }) : null}
          </div>      
        </div>
        
      
  </Layout>)
}

export default OmxSingle;

