import React from 'react';
import CategoryTag from './categoryTag';
import BreadCrumbs from './breadcrumbs';
import Button from '../button';
import ParsedDate from '../date';

import './style.scss';

const Hero = ({ title, date, showDate, bgcolor, img, heroStyle, heroImageBottom, excerpt, category, path, ctaLink, ctaText, cta2Link, cta2Text }) => {

  const getBgColor = (color) => {
    switch (color) {
      case 'yellow':
        return '#ffdc00';
      default:
        return '#000';
    }
  }
  const colorInlineStyles = {
    background: `${getBgColor(bgcolor)}`
  }
  const postInlineStyles = {
    background: `url("${img  ? img.resolutions.src : ''}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }



  return (
    <>
    {heroStyle === 'page' ?
     <div>
       {path ?
        <div className="pageHeroBreadCrumbsContainer">
          <BreadCrumbs path={path} />
        </div>
      :null}
     <div className={`pageHero ${heroImageBottom ? 'pageHeroImgOnBottom' : ''}`}>
      {img && !heroImageBottom ? 
        <>
          <div className="pageHeroMask"></div>
          <div style={postInlineStyles} className="pageHeroImg"></div>
        </>
      :null}
       <div className='pageHeroContainer'>
        <div className="pageHeroContent">
          <h1>{title}</h1>
          <p className="pageHeroExcerpt">{excerpt}</p>
          <div className="pageHeroCtaContainer">
            {ctaText && ctaLink ?
            <Button text={ctaText} link={ctaLink} color="orange" marginX marginY />
            : null}
            {cta2Text && cta2Link ?
            <Button text={cta2Text} link={cta2Link} color="orange" marginX marginY />
            : null}
          </div>
          
        
        </div>
       
       </div>
        {img && heroImageBottom ? 
            <div style={postInlineStyles} className="pageHeroImg"></div>
          :null}
       
   </div>
   
   </div>
    : null}
  {heroStyle === 'post' ?
    <div>
      <div className="postHero">
        {img && !heroImageBottom ? 
        <div className="postHeroImgContainer" style={postInlineStyles}>    
        </div>: null}
         
         <BreadCrumbs path={path} />
        <div className={`postHeroContent ${heroImageBottom ? 'centeredHero' : ''}`}>
       
          <h1>{title}</h1>
          <p>{excerpt}</p>
          <CategoryTag category={category} />
          {date && showDate ? <ParsedDate date={date} marginX /> : null}
        </div>
        {img && heroImageBottom ? 
        <div className="postHeroImgContainer" style={postInlineStyles}>    
        </div>: null}
    </div>
    </div>
    :null}

{heroStyle === 'pageNoHeroImg' ?
     <div>
       {path ?
        <div className="pageHeroBreadCrumbsContainer">
          <BreadCrumbs path={path} />
        </div>
      :null}
     <div className="pageNoHeroImg">
       <div className="pageHeroContainer">
        <div className="pageHeroContent">
          <h1>{title}</h1>
          <p className="pageHeroExcerpt">{excerpt}</p>
          <div className="pageHeroCtaContainer">
            {ctaText && ctaLink ?
            <Button text={ctaText} link={ctaLink} color="orange" marginX marginY />
            : null}
            {cta2Text && cta2Link ?
            <Button text={cta2Text} link={cta2Link} color="orange" marginX marginY />
            : null}
          </div>
          
        
        </div>
       </div>
       
       
   </div>
   
   </div>
    : null}

{heroStyle === 'color' ?
     <div>
       {path ?
        <div className="pageHeroBreadCrumbsContainer">
          <BreadCrumbs path={path} />
        </div>
      :null}
     <div className="pageHero">
       {img ?
       <div className="pageHeroMask">
       </div>:null}
      <div style={colorInlineStyles} className="pageHeroImg"></div>
       <div className="pageHeroContainer">
        <div className="pageHeroContent">
          <h1>{title}</h1>
          <p className="pageHeroExcerpt">{excerpt}</p>
          <div className="pageHeroCtaContainer">
            {ctaText && ctaLink ?
            <Button text={ctaText} link={ctaLink} color="orange" marginX marginY />
            : null}
            {cta2Text && cta2Link ?
            <Button text={cta2Text} link={cta2Link} color="orange" marginX marginY />
            : null}
          </div>
          
        
        </div>
       </div>
       
       
   </div>
   
   </div>
    : null}

    </>
  );
}

export default Hero;